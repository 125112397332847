import { useRef } from "react";
import styles from './switchModal.module.scss'

const SwitchModal = (props) => {
    const modalBackground = useRef();
    const modal = useRef();

    return(
        <>
            <div className={styles.popup + ' switch-popup'} ref={modalBackground} onClick={props.closeModalSwitch}></div>
            <div ref={modal} className={styles.container + ' switch-container'}>
                <p className={styles.closeIcon}><span onClick={props.closeModalSwitch}>&#10005;</span></p>
                <h2>Are you a healthcare provider?</h2>
                <a href='/hcp' className={styles.button}>I am a healthcare provider</a>
                <p className={styles.content}>By clicking “I am a healthcare provider” you are certifying that you are a licensed US healthcare professional (HCP) and a US resident. </p>
                <button onClick={props.closeModalSwitch} className={styles.button}>I am not a healthcare provider</button>
            </div>
        </>
    )
}

export default SwitchModal;