import ExitLink from '../exitLink/exitLink';

import styles from './footer.module.scss';
import amarinLogo from '../../images/amarin-logo.png'


const Footer = (props) => {
  return(
    <div className={styles.footer}>
        {props.hcp === false && <p className={styles.header}> <ExitLink setLink={props.setLink} linkStyle={styles.link} btnText="CLICK HERE"/> <span>IF YOU ARE A HEALTHCARE PROFESSIONAL</span></p>}
      <p className={styles.header + " " + styles.footerLink}><a href="https://amarincorp.com/disclaimer.html" target="_blank" rel="noopener noreferrer">Disclaimer</a> <span>|</span> <a href="https://amarincorp.com/privacy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
      <p>The Amarin logo is a registered trademark of Amarin Pharmaceuticals Ireland Limited. All other trademarks are the property of their respective companies.</p>
      <p>&copy; 2022 Amarin Pharmaceuticals Ireland Limited.</p>
      <p>All rights reserved.</p>
      {props.hcp === false && <p>Contact Us: &nbsp;
          <a href="tel:18552887884">1-855-CVTRUTH</a></p>}
      <p>Amarin Pharmaceuticals Ireland Limited is not responsible for material contained on other non-Amarin controlled websites or other social media sites.</p>
      <p>US-AMRN-{props.hcp === false ? '00650' : '00649'}&nbsp;&nbsp;12/22</p>

      <div className={styles.logo}>
        <a href="https://amarincorp.com/" target="_blank" rel="noopener noreferrer"><img src={amarinLogo} alt="amarin logo" /></a>
      </div>
    </div>
  )
}

export default Footer;