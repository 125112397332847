import React from 'react'

import style from './heroBanner.module.scss'

const HeroBanner = ({header}) => {
    return(
        <div className={style.heroBanner}>
            <div className='container'>
                <h1>{header}</h1>
            </div>
        </div>
    )
        
}

export default HeroBanner;