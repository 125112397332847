import React from 'react'

import './twoColumnText.scss'

const TwoColumnText = (props) => {
    return(
        <div className={`twoColumnText ${props.customClass}`}>
                <p className="firstText" >{props.firstText}</p>
                <p className="secondText" >{props.secondText}</p>
        </div>
    )
        
}

export default TwoColumnText;