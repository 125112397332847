import React from 'react';

import './section.scss'

const Section = (props) => {
    return(
        <>
            <div id={props.id} className={`section-anchor ${props.customClass}`}>
            <section className={`section ${props.customClass} ${props.imgPosition === 'left' ? 'leftImg' : ''}`}>
                <div className='imageContainer' style={{display:`${!props.img ? 'none' : ''}`}}>
                    <img className={props.imgClass} src={props.img} alt={props.altImg} />
                </div>
                <div className='textContainer'>
                    <h3>{props.header}</h3>
                    {props.children}
                </div>
            </section>
                {props.references &&
                    <section className={`section one-column pt1`}>
                        <p>{props.references}</p>
                    </section>
                }
            </div>
        </>

    )
        
}

export default Section;