import { useState, useRef } from 'react';
import Scrollspy from 'react-scrollspy'

import TopBanner from '../components/topBanner/topBanner';
import MainMenu from '../components/mainMenu/mainMenu';
import HeroBanner from '../components/heroBanner/heroBanner';
import TwoColumnText from '../components/twoColumnText/twoColumnText';
import LineBreak from '../components/lineBreak/lineBreak';
import Section from '../components/section/section';
import Highlight from '../components/highlight/highlight';

import ExitModal from '../components/exitModal/exitModal'

import FFImage from '../images/fibrate-failure.svg'
import scales from '../images/scales.svg'
import stethoscope from '../images/stethoscope.svg'

import '../App.scss';
import Footer from '../components/footer/footer';
import SectionBlue from '../components/sectionBlue/sectionBlue';
import { Fragment } from 'react';
import SwitchModal from '../components/switchModal/switchModal';

function Home() {

    const [externalLink, setExternalLink] = useState('');
    const mainContainer = useRef();

    const setLink = (e) => {
        setExternalLink(e.target.dataset.url);
        mainContainer.current.classList.remove('modal-close')
        mainContainer.current.classList.add('modal-open')
    }

    const closeModal = () => {
      mainContainer.current.classList.add('modal-close')
      mainContainer.current.classList.remove('modal-open')
    }

    const openModalSwitch = (e) => {
      mainContainer.current.classList.remove('modal-switch-close')
      mainContainer.current.classList.add('modal-switch-open')
  }
  const closeModalSwitch = (e) => {
      mainContainer.current.classList.add('modal-switch-close')
      mainContainer.current.classList.remove('modal-switch-open')
  }
  
    

    return (
      <div ref={mainContainer}>

        <TopBanner question="Not a patient?" action="Go to the healthcare professionals page" setLink={openModalSwitch} link="/hcp"/>
        <MainMenu page="patient" firstLink="No proven CV benefit" secondLink="FDA response" thirdLink="Take action now" sectionLink1="proofAgainstFibrates" sectionLink2="fdaResponse" sectionLink3="takeAction" />
        <HeroBanner header="Learn the truth about fenofibrates" />
        <TwoColumnText 
                firstText={<Fragment>If you are taking <strong>fenofibrates with statins</strong> for a reduced risk of a heart attack or stroke, <strong>you're not getting the protection you think you are</strong>.</Fragment>}
                
                secondText={<Fragment>While fenofibrates and statins may seem effective because they lower your triglycerides, <strong>adding fenofibrates did not reduce the risk of cardiovascular events, such as a heart attack or stroke.</strong>  Here's why.</Fragment>}
        />
        <LineBreak />


        <Section header="No proven CV benefit" id="proofAgainstFibrates" img={FFImage} imgClass="icon" altImg="Fibrate Failure">
          <Highlight text="Fibrates, when added to a statin, have repeatedly failed to prove cardiovascular benefit in 3 separate trials." />
          <p>A <strong>2022 clinical study* was discontinued</strong> because it failed to prove that taking pemafibrate with a statin received any additional cardiovascular protection. In fact, this study was recently published and presented at a major medical conference sponsored by the American Heart Association. <strong>The failure of this study is the latest in a series of 3 failed cardiovascular outcomes trials,<sup>&dagger;</sup> in which fenofibrates and the broader class of fibrate drugs have failed to demonstrate a cardiovascular benefit for statin-treated patients with a high risk of cardiovascular disease</strong>.</p>
            <p className='m-b-0'><small className='indent'>*The PROMINENT study. <em>N Engl J Med</em>. 2022;387(21):1923-1934.</small></p>
            <p><small className='indent'><sup>&dagger;</sup>Failed cardiovascular trials are FIELD, ACCORD-Lipid, and PROMINENT.</small></p>
        </Section>
        <SectionBlue page="patient" externalLink="https://vascepa.com/" setLink={setLink} count={1}/>
        <Section header="FDA response" id="fdaResponse" img={scales} imgClass="icon" imgPosition='left' altImg="Scale icon">
          <p>The <strong>FDA revoked the approval of fenofibrates and statins for CV risk</strong> in 2016, concluding that fibrates should not be used in combination with statins because <strong>the risks of these medicines outweigh the benefits.</strong></p>
          <p>Despite the overwhelming evidence, fibrates continue to be the second most commonly prescribed lipid agents after statins, prescribed for about 2 million Americans annually, most often with statins.<sup>&Dagger;&sect;</sup></p>
          <Highlight text="Millions of patients at an increased risk of heart attack or stroke are still taking fenofibrates that have not been proven to reduce the risk of CV events. Are you one of them?" />
            <p className='m-b-0'><small className='indent'><sup>&Dagger;</sup>Symphony Prescriber Weekly Data, Week Ending 03/25/2022.</small></p>
            <p><small className='indent'><sup>&sect;</sup>Data may include a combination of prescriptions for CV risk reduction and/or triglyceride reduction. Fibrates are indicated as an adjunct to diet for the treatment of adults with severe hypertriglyceridemia.</small></p>
        </Section>
        <LineBreak />
        <Section id="takeAction" header="Take action now"  img={stethoscope} imgClass="icon" altImg="Take action">
          <p>If you are taking fenofibrates in addition to statins to reduce your risk of heart attack or stroke, <strong>ask your doctor why.</strong></p>
          <Highlight text="Talk to your doctor today about changing your treatment to FDA-approved medications." />
        </Section>
        <div id="test"></div>
        <SectionBlue page="patient" externalLink="https://vascepa.com/" setLink={setLink} count={2}/>
        <div className='modal-container'>
          <ExitModal externalUrl={externalLink} closeModal={closeModal} />
        </div>
        <div className='modal-switch-container'>
          <SwitchModal closeModalSwitch={closeModalSwitch} />
        </div>
        <Footer hcp={false} setLink={openModalSwitch} />
      </div>
    );
  }
  
  export default Home;