import { useRef } from "react";

import styles from './exitModal.module.scss'

const ExitModal = ({externalUrl, closeModal,isHCPLink}) => {
    const modalBackground = useRef();
    const modal = useRef();

    return(
        <>
            <div className={styles.popup + ' popup'} ref={modalBackground} onClick={closeModal}></div>
            <div ref={modal} className={styles.container + ' exit-container'}>
                <p className={styles.closeIcon}><span onClick={closeModal}>&#10005;</span></p>
                {isHCPLink && <p className={styles.content}>You are now leaving <strong>TheTruthAboutFenofibrates.com</strong>.</p>}
                {!isHCPLink && <p className={styles.content}>You are now leaving <strong>TheTruthAboutFenofibrates.com</strong>. You will be directed to an Amarin promotional website to learn about a prescription cardiovascular treatment.</p>}
                <a href={externalUrl} onClick={closeModal} target="_blank" rel="noreferrer" className={styles.button}>Continue</a>
            </div>
        </>
    )
}

// default props, if the component props(isHCPLink) dont have any value it will always be false
ExitModal.defaultProps = {
    isHCPLink: false
  }

export default ExitModal;