import { useRef, useState, useEffect } from 'react';
import Scrollspy from 'react-scrollspy'

import styles from './mainMenu.module.scss'

const MainMenu = (props) => {
    const mobileButton = useRef();

    const [offset, setOffset] = useState(0);
    const [scroll, setScroll] = useState(false);


    useEffect(() => {

        // delay the function run after scrollspy to remove class
        setTimeout(() => {
            removeClass()
          }, 1);
        //   add class again after scrolling and currently in no proven
          window.addEventListener("scroll", () => {
            if(window.scrollY > 500){
                setScroll(true)
            }
            else {
                removeClass()
                setScroll(false)
            }
          });
          
        const onScroll = () => {
            setOffset(window.pageYOffset);
        }
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    const closeNav = () => {
        mobileButton.current.click();
    }
    // function to remove class
    const removeClass = () => {
        let activeLi = document.getElementById("initialLi")
        activeLi.classList.remove("is-active")
    }

    return(
        <header className={`${styles.mainMenu} ${offset > 40 ? 'small-menu' : ''}`}>
            <div className={styles.container}>
                <h2>The Truth About Fenofibrates</h2>
                <div className={styles.linkContainer}>
                    <input type="checkbox" className={styles.checkbox} id="navi-toggle" />
                    <label htmlFor="navi-toggle" ref={mobileButton} className={styles.button}>
                        <span className={styles.icon}>&nbsp;</span>
                    </label>
                    <nav className={styles.nav}>
                    <Scrollspy style={styles.list} items={ [props.sectionLink1, props.sectionLink2, props.sectionLink3] } currentClassName="is-active">
                        <li id='initialLi' className={scroll ? 'not-active' : ''}>
                            <a href={"#" + props.sectionLink1} onClick={closeNav} id={`${props.page.toUpperCase()}_${props.firstLink.replaceAll(' ','_').toUpperCase()}_MAIN_MENU_LINK`}>{props.firstLink}</a>
                        </li>
                        <li>
                            <a href={"#" + props.sectionLink2} onClick={closeNav}  id={`${props.page.toUpperCase()}_${props.secondLink.replaceAll(' ','_').toUpperCase()}_MAIN_MENU_LINK`}>{props.secondLink}</a>
                        </li>
                        <li>
                            <a href={"#" + props.sectionLink3} onClick={closeNav}  id={`${props.page.toUpperCase()}_${props.thirdLink.replaceAll(' ','_').toUpperCase()}_MAIN_MENU_LINK`}>{props.thirdLink}</a>
                        </li>
                    </Scrollspy>
                    </nav>
                </div>
            </div>
        </header>
    )
        
}

export default MainMenu;