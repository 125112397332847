import ExitLink from '../exitLink/exitLink'

import style from './topBanner.module.scss'

const TopBanner = (props) => {
    return(
        <div className={style.topBanner}>
            <div className='container'>
                    {props.question} {props.hcp ? <a href={props.link}>{props.action}</a> : <ExitLink setLink={props.setLink} linkStyle={style.link} btnText={props.action}/>} 
            </div>
        </div>
    )
        
}

export default TopBanner;