
import { useState, useRef } from 'react';

import TopBanner from '../components/topBanner/topBanner';
import MainMenu from '../components/mainMenu/mainMenu';
import HeroBanner from '../components/heroBanner/heroBanner';
import TwoColumnText from '../components/twoColumnText/twoColumnText';
import LineBreak from '../components/lineBreak/lineBreak';
import Section from '../components/section/section';
import Highlight from '../components/highlight/highlight';
import ExitLink from '../components/exitLink/exitLink'
import ExitModal from '../components/exitModal/exitModal'

import FFImage from '../images/fibrate-failure.svg'
import scales from '../images/scales.svg'
import stethoscope from '../images/stethoscope.svg'

import '../App.scss';
import Footer from '../components/footer/footer';
import SectionBlue from '../components/sectionBlue/sectionBlue';

import { Fragment } from 'react';

function HCP() {

  const [externalLink, setExternalLink] = useState('');
  const mainContainer = useRef();
  const [isHCPLink, setIsHCPLink] = useState(false);

  const setLink = (e) => {
      setExternalLink(e.target.dataset.url);
      mainContainer.current.classList.remove('modal-close')
      mainContainer.current.classList.add('modal-open')
      console.log(e.target.dataset.url);
  }

  const closeModal = () => {
    mainContainer.current.classList.add('modal-close')
    mainContainer.current.classList.remove('modal-open')
  }



  return (
    <div ref={mainContainer}>
        <TopBanner question="Not a healthcare professional?" hcp action="Go to the patient page" link="/"/>
        <MainMenu page="hcp" firstLink="No proven CV benefit" secondLink="FDA revoked" thirdLink="Break the pattern" sectionLink1="notProven" sectionLink2="fdaRevoked" sectionLink3="breakThePattern" />
        <HeroBanner header="Learn the truth about fenofibrates" />
        <TwoColumnText customClass="bp1 p-b-0"
                firstText={<Fragment>Despite new and existing evidence that further demonstrated <strong>fenofibrates + statins should not be used</strong> for cardiovascular risk reduction, fibrates are still being prescribed for approximately 2 million patients.<sup>1-5</sup>*</Fragment>}
                secondText={<Fragment>If your ultimate goal is to lower CV risk, especially after a prior CV event such as MI or stroke, prescribing fenofibrates to statin-treated patients showed no CV benefit.<sup>1-5</sup> Here are some compelling reasons not to prescribe fenofibrates.</Fragment>}
        />
        <Section customClass="no-padding" header="" id="">
            <p><small className='indent'>*Symphony Prescriber Weekly Data, Week Ending 03/25/2022.</small></p>
        </Section>
        <LineBreak />
        <Section header="No proven CV benefit" id="notProven" img={FFImage} altImg="Not proven" imgClass="icon">
          <p><strong>The recently discontinued phase 3 <ExitLink externalLink="https://www.nejm.org/doi/full/10.1056/NEJMoa2210645?query=featured_cardiology" setLink={(e) => setLink(e, setIsHCPLink(true))} btnText="PROMINENT" linkStyle='link'></ExitLink> study showed pemafibrate did not reduce CV risk for statin-treated patients.</strong> Furthermore, pemafibrate was associated with a higher incidence of adverse renal events and venous thromboembolism.<sup>4</sup></p>
          <p>This is just the latest in a series of randomized trials, following <ExitLink externalLink="https://www.nejm.org/doi/10.1056/NEJMoa1001282?url_ver=Z39.88-2003&rfr_id=ori:rid:crossref.org&rfr_dat=cr_pub%20%200www.ncbi.nlm.nih.gov" setLink={(e) => setLink(e, setIsHCPLink(true))} btnText="ACCORD-Lipid" linkStyle='link'></ExitLink> and <ExitLink externalLink="https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(05)67667-2/fulltext" setLink={(e) => setLink(e, setIsHCPLink(true))} btnText="FIELD" linkStyle='link'></ExitLink>, in which <strong>fenofibrates and the broader class of fibrate drugs have failed to demonstrate a cardiovascular benefit</strong> for statin-treated patients with a high risk of cardiovascular disease.<sup>1,3-5</sup></p>
          <p>This failure adds to the mounting evidence that lowering triglyceride levels with fenofibrates + statin therapy does not correlate directly with CV risk reduction.<sup>1,3-5</sup></p>
        </Section>
        <SectionBlue page="hcp" title="Why give your patients an unproven treatment?" externalLink="https://vascepahcp.com/" setLink={(e) => setLink(e, setIsHCPLink(false))} count={1}/>
        <Section header="FDA revoked" id="fdaRevoked" img={scales} imgPosition="left" altImg="Scale icon" imgClass="icon">
            <p>The FDA previously revoked the approval of fenofibrates + statins label indication for CV risk reduction in 2016, concluding that <strong>fenofibrates should not be used in combination with statins</strong> because the risks outweigh the benefits.<sup>2,3</sup></p>
        </Section>
        <LineBreak />
        <Section customClass="pb0" header={<Fragment>Break the pattern of fenofibrate use in CV risk reduction<sup>&dagger;</sup></Fragment>} id="breakThePattern" img={stethoscope} imgClass="icon" altImg="It's time to reconsider fenofibrates"
          references={<Fragment><small><strong>References: 1.</strong> Kowa to discontinue K-877 (pemafibrate) "PROMINENT" cardiovascular outcomes study. News release. Kowa Research Institute, Inc.; April 8, 2022. Accessed December 6, 2022. https://www.prnewswire.com/news-releases/kowa-to-discontinue-k-877-pemafibrate-prominent-cardiovascular-outcomes-study-301520956.html.
              <strong> 2.</strong> Department of Health and Human Services. [Docket no. FDA–2016–N–1127]: AbbVie Inc., et al; Withdrawal of approval of indications related to the coadministration with statins in applications for niacin extended-release tablets and fenofibric acid delayed-release capsules. <i>Federal Register</i>. 2016;81(74):22612-22613. <strong>3. </strong>ACCORD Study Group; Ginsberg HN, Elam MB, Lovato LC, et al. Effects of combination lipid therapy in type 2 diabetes mellitus. <i>N Engl J Med</i>. 2010;362(17):1563-1574. <strong>4.</strong> Das Pradhan A, Glynn RJ, Fruchart J-C, et al; for the PROMINENT Investigators. Triglyceride lowering with pemafibrate to reduce cardiovascular risk. <i>N Engl J Med</i>. 2022;387(21):1923-1934. 
              <strong> 5.</strong> Keech A, Simes RJ, Barter P, et al; FIELD Study Investigators. Effects of long-term fenofibrate therapy on cardiovascular events in 9795 people with type 2 diabetes mellitus (the FIELD study): randomised controlled trial. <i>Lancet.</i> 2005;366(9500):1849-1861.<strong> 6.</strong> American Heart Association. Cholesterol medications. https://www.heart.org/en/health-topics/cholesterol/prevention-and-treatment-of-high-cholesterol-hyperlipidemia/cholesterol-medications. Accessed December 14, 2022.</small></Fragment>}>
            <p>Despite the overwhelming evidence, fibrates continue to be the second most commonly prescribed lipid agents after statins, prescribed for approximately 2 million Americans annually, most often with statins.*</p>
            <Highlight text={<Fragment>Millions of fibrate patients are at increased risk of MI and stroke and may be unknowingly receiving medications ineffective at reducing their <span class="no-wrap">CV risk.<sup>1,3-5</sup></span></Fragment>} />
            <p className="pb-40"><strong>It’s time to break the fenofibrate habit and consider only FDA-approved therapies for CV risk reduction.</strong></p>
            <p><small className='indent'><sup>&dagger;</sup>Data may include a combination of prescriptions for CV risk reduction and/or triglyceride reduction. Fibrates are indicated as an adjunct to diet for the treatment of adults with severe hypertriglyceridemia.<sup>6</sup></small></p>
        </Section>
        <SectionBlue page="hcp" title="Why prescribe an unproven treatment?" externalLink="https://vascepahcp.com/" setLink={(e) => setLink(e, setIsHCPLink(false))} count={2}/>
        <div className='modal-container'>
          <ExitModal externalUrl={externalLink} closeModal={closeModal} isHCPLink={isHCPLink}/>
        </div>
        <Footer hcp={true} setLink={(e) => setLink(e, setIsHCPLink(false))} externalLink="https://amarincorp.com/"/>
      </div>
    );
  }
  
  export default HCP;
