import styles from './sectionBlue.module.scss'

const SectionBlue = (props) => {



  return (
    <div className={styles.sectionBlue}>
      <div>
        <div className={styles.title}>
          <p>
            {props.title}
          </p>
        </div>
        <button id={`${props.page.toUpperCase()}_${props.button.replaceAll(' ','_').toUpperCase()}_PAGE_BUTTON_${props.count}`} className={styles.button} data-url={props.externalLink} onClick={props.setLink}>{props.button}</button>
      </div>

    </div>
  )
}

SectionBlue.defaultProps = {
  title: 'Why continue taking medications with no proven CV benefit?',
  button: 'Discover a proven prescription treatment option'
};


export default SectionBlue;