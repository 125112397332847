import React, {useEffect} from 'react';
import {Routes, Route, useLocation, Navigate} from 'react-router-dom';

import Home from './routes/home.component';
import HCP from './routes/hcp.component';


import './App.scss';

const NotFound = () => <Navigate to="/" />;

function App() {

  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change


    return (
            <Routes>
              <Route exact index element={<Home/>} />
              <Route exact path='/hcp' element={<HCP/>} />
              <Route path='*' element={<NotFound/>} />
            </Routes>
    );
  }
  
  export default App;