import React from 'react'

import style from './highlight.module.scss'

const Highlight = ({text}) => {
    return(
        <div className={style.highlight}>
            <div className={style.topBorder}></div>
            <p>{text}</p>
        </div>
    )
        
}

export default Highlight;